<template>
	<!-- 我的设备 -->
	<div style="background: #eee">
		<van-sticky>
			<van-nav-bar
				title="我的设备"
				left-text="返回"
				:right-text="rightText"
				left-arrow
				@click-left="goback"
				@click-right="showBorrowFun"
			/>
			<!-- 标签页 -->
			<van-tabs
				swipeable
				@click="tabChange"
				v-show="groupId != null && groupId != 'null' && groupId != ''"
			>
				<van-tab key="0" title="我的设备" name="0" />
				<van-tab key="1" title="群组设备" name="1" />
			</van-tabs>
		</van-sticky>
		<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
			<van-cell-group
				v-for="(item, index) in deviceCategoryList"
				:key="index"
				style="margin: 1.2rem; text-align: left"
				inset
			>
				<van-field
					label-width="8rem"
					:label="item.categoryName"
					:value="
						'未使用:' + item.inputCount + ' 已使用:' + item.outCount + ' 转借:' + item.lockCount
					"
					@click="toDevice(item.id)"
				/>
			</van-cell-group>
		</van-list>
		<!-- 添加按钮 -->
		<div style="width: 100%; position: fixed; bottom: 1rem">
			<!--      <van-button v-show="enterPower" type="info" @click="showAdd=true" style="margin-right:2.5rem">添加设备</van-button>-->
			<van-button
				type="primary"
				v-show="authorization('/home-admin/device/device-borrow')"
				style="margin: auto"
				@click="toSub"
				>转借记录</van-button
			>
		</div>
		<!-- <van-button v-show="enterPower" round type="info" icon="plus" style="position:fixed;bottom:1rem;left:1rem;padding:1.6rem 1.1rem" @click="showAdd=true"></van-button>
    <van-button v-show="enterPower" round type="info" style="position:fixed;bottom:0.8rem;right:1rem;padding:1.9rem 1.1rem" @click="showAdd=true">转借<br>记录</van-button> -->
		<!-- 弹出层 -->
		<van-popup v-model="showAdd" position="bottom">
			<van-form @submit="onSubmit">
				<van-field
					v-model="deviceAddParams.deviceNum"
					label="设备编号"
					clearable
					placeholder="请填写设备编号"
					:rules="[{ required: true }]"
				>
					<template #button>
						<van-button type="primary" @click="scanCode()" size="small">扫描录入</van-button>
					</template>
				</van-field>
				<!--        <van-field v-model="deviceAddParams.sapNum" label="ASP编号" placeholder='请填写ASP编号' clearable :rules="[{ required: true}]"/>-->
				<van-field
					v-model="deviceAddParams.brand"
					label="名称"
					clearable
					placeholder="请填写名称"
					:rules="[{ required: true }]"
				/>
				<!--        <van-field v-model="deviceAddParams.describe" label="描述"  placeholder="请填写描述"/>-->
				<div style="margin: 1rem">
					<van-button round block type="info" native-type="submit">提交</van-button>
				</div>
			</van-form>
		</van-popup>
		<van-popup v-model="showBorrow" style="width: 18rem">
			<van-form @submit="onSubmit">
				<van-field
					readonly
					clickable
					name="picker"
					:value="inputUserName"
					label="借给谁"
					placeholder="点击选择师傅"
					:rules="[{ required: true }]"
					@click="showMasterPicker = true"
				/>
				<van-field
					readonly
					clickable
					name="picker"
					:value="deviceNum"
					label="充电桩编号"
					placeholder="点击选择充电桩编号"
					@click="showChangeDevice = true"
				/>
				<van-field
					readonly
					clickable
					name="picker"
					:value="categoryName"
					label="类型"
					placeholder="点击选择类型"
					v-show="deviceNum == null || deviceNum == ''"
					:rules="
						deviceNum == null || deviceNum == '' ? [{ required: true }] : [{ required: false }]
					"
					@click="showClassPicker = true"
				/>
				<van-field
					v-model="subParams.count"
					name="设备数量"
					label="设备数量"
					placeholder="设备数量"
					type="number"
					v-show="deviceNum == null || deviceNum == ''"
					:rules="
						deviceNum == null || deviceNum == '' ? [{ required: true }] : [{ required: false }]
					"
				/>
				<!--        <van-field  readonly label="设备" label-width="50">-->
				<!--          <template #button>-->
				<!--            <van-row v-for="(item,index) in deviceNumArray" :key="index" style="width: 12rem">-->
				<!--              <van-col span="20"><span>{{removeSpecial(item)}}</span></van-col>-->
				<!--              <van-col span="2"><span style="color: red" @click="deleteChangeDevice(index)">X</span></van-col>-->
				<!--            </van-row>-->
				<!--            <br/>-->
				<!--            <van-row style="width: 130px">-->
				<!--              <van-button native-type="button" type="primary"  size="small" @click="showChangeDevice = true">添加设备</van-button>-->
				<!--            </van-row>-->

				<!--          </template>-->
				<!--        </van-field>-->

				<div style="margin: 16px">
					<van-button round block type="info" native-type="submit">提交</van-button>
				</div>
			</van-form>
		</van-popup>
		<van-popup v-model="showMasterPicker" position="bottom">
			<van-picker
				show-toolbar
				:columns="distributionCol"
				value-key="userName"
				@confirm="masterConfirm"
				@cancel="showMasterPicker = false"
			/>
		</van-popup>
		<van-popup v-model="showClassPicker" position="bottom">
			<van-picker
				show-toolbar
				:columns="deviceCategoryCol"
				value-key="categoryName"
				@confirm="classConfirm"
				@cancel="showClassPicker = false"
			/>
		</van-popup>
		<van-popup v-model="showChangeDevice" position="bottom">
			<van-field
				style="position: absolute; top: 35px; margin: auto; z-index: 99"
				placeholder="搜索"
				v-model="searchDevice"
				input-align="center"
				@input="onInput"
			></van-field>
			<van-picker
				show-toolbar
				:columns="changeDeviceList"
				value-key="deviceNumAndCategoryName"
				@confirm="classChangeDevice"
				@cancel="showChangeDevice = false"
			/>
		</van-popup>
	</div>
</template>
<script>
	import moment from 'moment';
	import { Toast } from 'vant';
	import { authorization } from '../authorization/authorization';
	export default {
		data() {
			return {
				rightText: this.getRightText(),
				searchDevice: '',
				showChangeDevice: false,
				showAdd: this.$route.query.showAdd === undefined ? false : this.$route.query.showAdd,
				showBorrow: false,
				loading: false,
				finished: false,
				deviceManageList: [],
				deviceCategoryList: [],
				deviceAddParams: {
					deviceNum: this.$route.query.deviceNum,
					sapNum: '',
					brand: '',
					describe: '',
				},
				params: {
					size: 5,
					page: 1,
				},
				scan: '',
				distributionCol: [],
				deviceCategoryCol: [],
				inputUserName: '',
				categoryName: '',
				deviceNum: '',
				subParams: {
					inputUser: '',
					categoryId: '',
					count: '',
					deviceIds: [],
				},
				showMasterPicker: false,
				showClassPicker: false,
				changeDeviceList: [],
				oldChangeDeviceList: [],
				deviceNumArray: [],
				groupId: localStorage.getItem('groupId'),
			};
		},
		created() {
			this.queryCategoryList();
			this.queryUserList();
			this.queryCategoryDrown();
		},
		/*beforeRouteEnter(to, from, next) {
	   scanText = from.params.scanText
	   if(scanText !== "" && scanText !== undefined){
	     showAdd =true
	   }else {
	     showAdd =false
	   }
	   next(); // 必需调用
	 },*/
		methods: {
			authorization,
			getRightText() {
				if (authorization('/home-admin/device/device-manage/borrow')) {
					return '设备转借';
				} else {
					return '';
				}
			},
			tabChange(name) {
				if (name == 1) {
					this.params.userId = this.groupId;
				} else {
					this.params.userId = null;
				}
				this.params.page = 1;
				this.deviceCategoryList = [];
				this.loading = true;
				this.finished = false;
				this.queryCategoryList();
			},
			onInput() {
				let newArray = [];
				for (let i = 0; i < this.changeDeviceList.length; i++) {
					if (this.changeDeviceList[i].deviceNum.indexOf(this.searchDevice) >= 0) {
						newArray.push(this.changeDeviceList[i]);
					}
				}
				if (this.searchDevice === '' || this.searchDevice === undefined) {
					this.changeDeviceList = this.oldChangeDeviceList;
				} else {
					this.changeDeviceList = [];
					this.changeDeviceList = newArray;
				}
			},
			deleteChangeDevice(index) {
				this.subParams.deviceIds.splice(index, 1);
				this.deviceNumArray.splice(index, 1);
			},
			removeSpecial(str) {
				return str.replace(/\r|\n|\s/gi, '');
			},
			toSub() {
				this.$router.push({ path: '/SubHistory' });
			},
			showBorrowFun() {
				this.subParams = {};
				this.inputUserName = '';
				this.categoryName = '';
				this.getDeviceByCategory();
				this.showBorrow = true;
			},
			toDevice(categoryId) {
				this.$router.push({
					path: '/DeviceManage',
					query: { categoryId: categoryId, userId: this.params.userId },
				});
			},
			masterConfirm(value) {
				this.inputUserName = value.userName;
				this.subParams.inputUser = value.id;
				this.showMasterPicker = false;
			},
			classChangeDevice(value) {
				this.subParams.deviceIds = [];
				this.subParams.deviceIds.push(value.id);
				this.deviceNum = value.deviceNumAndCategoryName;
				this.showChangeDevice = false;
			},
			classConfirm(value) {
				this.categoryName = value.categoryName;
				this.subParams.categoryId = value.id;
				this.showClassPicker = false;
			},
			getDeviceByCategory() {
				this.axios({
					method: 'get',
					url: '/v1/web/device-manage/list-device-manage',
					params: {
						numNotNull: 'true',
						status: 0,
					},
				}).then((res) => {
					this.changeDeviceList = res.data.data.list;
					this.oldChangeDeviceList = res.data.data.list;
				});
			},
			isDeleteFun(createUser, status) {
				let userId = localStorage.getItem('userId');
				if (createUser === Number(userId) && status === 0) {
					return true;
				} else {
					return false;
				}
			},
			goback() {
				this.$router.push('/');
			},
			onLoad() {
				// 异步更新数据
				// setTimeout 仅做示例，真实场景中一般为 ajax 请求
				setTimeout(() => {
					this.params.page = this.params.page + 1;
					this.queryCategoryList();
				}, 1000);
			},
			queryCategoryDrown() {
				this.axios({
					method: 'get',
					url: '/v1/web/device-category/select-category',
				}).then((res) => {
					this.deviceCategoryCol = res.data.data.list;
				});
			},
			queryCategoryList() {
				this.axios({
					method: 'get',
					url: '/v1/web/device-category/select-category',
					params: this.params,
				}).then((res) => {
					if (this.params.page === 1) {
						this.deviceCategoryList = [];
					}
					for (let i = 0; i < res.data.data.list.length; i++) {
						this.deviceCategoryList.push(res.data.data.list[i]);
					}
					if (res.data.data.list.length <= 0) {
						this.finished = true;
					}
					// 加载状态结束
					this.loading = false;
				});
			},
			dateFormat(time) {
				if (time !== null && time !== undefined) {
					return moment(time).format('YYYY-MM-DD HH:mm:ss');
				}
			},
			onSubmit() {
				this.axios({
					method: 'post',
					url: '/v1/web/device-manage/add-device-sub',
					data: this.subParams,
				}).then((res) => {
					if (res.data.code === 200) {
						this.showBorrow = false;
						this.deviceCategoryList = [];
						this.params.page = 1;
						this.deviceNumArray = [];
						this.subParams.deviceIds = [];
						this.changeDeviceList = [];
						this.oldChangeDeviceList = [];
						this.queryCategoryList();
						Toast.success('操作成功');
					} else {
						Toast.fail(res.data.message);
					}
				});
			},
			scanCode() {
				this.$router.push({ path: '/QCode', query: { isOpenScan: true } });
			},
			deleteDevice(id) {
				this.axios({
					method: 'delete',
					url: '/v1/h5/device-manage/delete-device-manage/' + id,
				}).then((res) => {
					if (res.data.code === 200) {
						this.deviceCategoryList = [];
						this.params.page = 1;
						this.queryDeviceList();
						Toast.success('删除成功');
					}
				});
			},
			queryUserList() {
				//所属师傅下拉
				this.axios({
					method: 'get',
					url: '/v1/web/user-info/select-master-drown',
				}).then((res) => {
					let list = res.data.data.list;
					let newList = [];
					for (let i = 0; i < list.length; i++) {
						list[i].userName = list[i].userName.substring(0, list[i].userName.indexOf('('));
						newList.push(list[i]);
					}
					this.distributionCol = newList;
				});
			},
		},
	};
</script>

<style></style>
